import React from 'react'
import toastr from 'toastr'
import YouTube from 'react-youtube';
import { connect } from 'react-redux';
import Input from '../../components/form/input'
import Form from '../../utility/Form';
import productimizeLogoSm from '../../assets/images/logo.png'
import { getToken, saveDomainDetails } from '../../actions/checklogin';

class CheckLogin extends React.Component {
    constructor(props) {
        super(props);
var loc = window.location.toString(),
    params = loc.split('?')[1];
//var paramsplit = params.split('&');
//console.log(paramsplit['shop']);
//console.log(params);

const queryString = window.location.search;
//console.log(queryString);
const urlParams = new URLSearchParams(queryString);
const shopnme = urlParams.get('shop')
//console.log(shopnme);


//console.log('shopname');
//console.log(document.getElementById('shopName').value);
        this.state = {
            store_hash : shopnme ? shopnme: null,
            //store_hash : 'id1enticard.myshopify.com',
            domainUrl: null,
            licenceKey: null,
            inputErrors: {},
            check: false,
            checkBilling: false,
            showYoutube: false
        }
        this.checkLogin = Form.getInstance(
            this, {},
            {}
        );
        this.openModal = this.openModal.bind(this)
    }

    componentWillReceiveProps(newProps) {
        const { store_hash } = this.state
        if (!newProps.token.isFetching && newProps.token.response != this.props.token.response) {
            let response = newProps.token.response
            if (newProps.token.isError) {
                toastr.error(response.message)
            } else {
                //console.log("response", response)
                response.success && this.setState({ check: true }, () => {
                    localStorage.setItem("token", response.token)
                    localStorage.setItem("plan_id", response.plan_id ? response.plan_id : 1)
                    localStorage.setItem("domain_id", response.domain_id);
                    localStorage.setItem("expiry_date", response.expiry_date);
                    localStorage.setItem("platform", response.platform)
                    localStorage.setItem("shopName", store_hash)
                    localStorage.setItem("accessToken", response.access_token)
                });
            }
        }
        if (!newProps.saveDomainData.isFetching && newProps.saveDomainData.response != this.props.saveDomainData.response) {
            let response = newProps.saveDomainData.response
            if (newProps.saveDomainData.isError) {
                toastr.error(response.message)
            } else {
                this.props.dispatch(getToken({ store_hash: store_hash }))
            }
        }
    }
    componentWillMount() {
        const { store_hash } = this.state
        this.props.dispatch(getToken({ store_hash: store_hash }))
    }
    getToken(data) {
        this.props.dispatch(getToken(data))
    }
    openModal() {
        this.setState({ showYoutube: true })
    }

    handleChange = (e, key) => {
        this.setState({ [key]: e.target.value || '' })
    }
    handleSubmit = e => {
        e.preventDefault()
        const { domainUrl, licenceKey, store_hash } = this.state
        if (!domainUrl || !licenceKey) {
            toastr.error("Primary Domain and Licence Key Can't be Empty")
            return false
        } else if (!store_hash) {
            toastr.error("Store name not available")
            return false
        }
        this.props.dispatch(saveDomainDetails({
            store_hash: store_hash,
            domain_url: domainUrl,
            license_key: licenceKey
        }))

    }

    render() {
        const opts = {
            height: '390',
            width: '640',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                controls: 0,
                rel: 0
            }
        };
        if (!this.state.check) {
            return (
                <div className="promize-configure-wrapper">
                    <div className="promize-overlay-container">
                        <div className="promize-logo-lg">
                        </div>
                        <div className="promize-key-value">
                            <h4 className="promize-secondary-title">Customize Your Product</h4>
                            <h2 className="promize-primary-title">Product Customisation <span className="promize-highlight-text">Made Easy</span></h2>
                        </div>
                        <div className="promize-work" onClick={() => this.openModal()}>
                            <a href="#"><i className="fa fa-play"></i>See how it works</a>
                        </div>
                    </div>
                    <div className="promize-config-form">
                        <div className="promize-logo-sm">
                            <img src={productimizeLogoSm} />
                        </div>
                        <div className="promize-form-title">Configuration</div>
                        <Input
                            fieldName={'domain_url'}
                            labelName={'Primary Domain'}
                            onChange={(e) => { this.handleChange(e, 'domainUrl') }}
                            validation={'false'}
                            value={this.state.domainUrl}
                            form={this.checkLogin}
                        />
                        <Input
                            fieldName={'licence_key'}
                            labelName={'Licence Key'}
                            onChange={(e) => { this.handleChange(e, 'licenceKey') }}
                            validation={'false'}
                            value={this.state.licenceKey}
                            form={this.checkLogin}
                        />
                        <button className="btn btn-promize-primary" onClick={this.handleSubmit}>Submit</button>
                        <p>Don't have licence key? <a target="_blank" href="http://productimize.com/">Contact Us</a></p>
                    </div>
                    {this.state.showYoutube && <div className="promize-popup">
                        <div className="promize-popup-inner">
                            <YouTube
                                videoId="tzYG452u2R0"
                                opts={opts}
                            />
                            <button className="popup-close" onClick={() => this.setState({ showYoutube: false })}><i className="fa fa-times"></i></button>
                        </div>
                    </div>
                    }
                </div>
            )
        } else {
            return this.props.children
        }
    }
}
function mapStateToProps(state) {
    return {
        token: state.token,
        saveDomainData: state.saveDomainData,
    };
}
export default connect(mapStateToProps)(CheckLogin);
