import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router, Route, Switch, BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import callAPIMiddleware from './middleware/callAPIMiddleware';
import reducer from './reducers';
import { ROUTE } from './config';
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import CheckLogin from './containers/App/checkLogin'
import './assets/css/theme.css';
import './assets/css/fontawesome.css';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({ basename: '/admin' });

const middleware = [thunkMiddleware, callAPIMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
if (process.env.NODE_ENV !== 'production') {
// middleware.push(createLogger());
}

const setRoutes = () => {
  const route = ROUTE;
  return route.map((eachRoute, index) => {
    if (eachRoute.private === true) {
      return <PrivateRoute key={index} path={eachRoute.path} meta={eachRoute.meta} exact={eachRoute.exact} path={eachRoute.path} section={eachRoute.component} component={eachRoute.component} permission={eachRoute.permission} />;
    }
    return <PublicRoute key={index} path={eachRoute.path} meta={eachRoute.meta} exact={eachRoute.exact} path={eachRoute.path} section={eachRoute.component} component={eachRoute.component} />;
  });
};

ReactDOM.render(
  <Provider store={createStore(reducer, composeEnhancers(applyMiddleware(...middleware)))}>
    <CheckLogin>
      <BrowserRouter basename="/admin">
        <Switch>
          {setRoutes()}
          {/* <PublicRoute exact={true} path='/login' component={Login}/> */}
          {/* <PublicRoute component={NotFound}/> */}
        </Switch>
      </BrowserRouter>
    </CheckLogin>
  </Provider>, document.getElementById('root'),
);
Date.prototype.toIsoString = function() {
  var tzo = -this.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
          var norm = Math.floor(Math.abs(num));
          return (norm < 10 ? '0' : '') + norm;
      };
  return this.getFullYear() +
      '-' + pad(this.getMonth() + 1) +
      '-' + pad(this.getDate()) +
      'T' + pad(this.getHours()) +
      ':' + pad(this.getMinutes()) +
      ':' + pad(this.getSeconds()) + 'Z'
      
}

var dt = new Date();
